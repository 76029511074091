import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Provision',
      url: '/provision',
      icon: 'build'
    },
    {
      title: 'Devices',
      url: '/device',
      icon: 'tablet-landscape'
    },
    {
      title: 'Logs',
      url: '/log',
      icon: 'newspaper'
    },
    {
      title: 'Properties',
      url: '/property',
      icon: 'bed'
    },
    {
      title: 'Accounts',
      url: '/account',
      icon: 'home'
    },
    {
      title: 'Users',
      url: '/user',
      icon: 'person'
    },
    {
      title: 'Configuration',
      url: '/configuration',
      icon: 'settings'
    }
  ];

  constructor(private platform: Platform) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
    });
  }

  ngOnInit() {
    // const path = window.location.pathname.split('admin/')[1];
    const path = window.location.pathname.split('')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
}
